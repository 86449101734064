import React from 'react';
import { Link } from '@latitude/link';
import Icon from '@/components/Icon/Icon';
import { BUTTON_STYLE, COLOR } from '@latitude/core/utils/constants';
import './_button.scss';
import classnames from 'classnames';
import { ICON_VARIANT, LAB_ICON_SIZE } from '@/utils/constants';

const BUTTON_THEME_STYLE = Object.freeze({
  HOMEPAGEBUTTON: BUTTON_STYLE.SECONDARY_INVERSE,
  APPLICATION: BUTTON_STYLE.PRIMARY,
  DEFAULT: BUTTON_STYLE.SECONDARY,
  DEFAULTINVERSE: BUTTON_STYLE.SECONDARY_INVERSE,
  OUTLINE: BUTTON_STYLE.TERTIARY,
  OUTLINEINVERSE: BUTTON_STYLE.TERTIARY
});

const Button = props => {
  const {
    isHomepageButton,
    className,
    targetBlank,
    theme = isHomepageButton ? 'HOMEPAGEBUTTON' : theme,
    icon,
    href,
    onClick,
    text,
    fullWidth,
    ...rest
  } = props;

  return (
    <div className="cta-button-wrapper">
      <Link
        className={classnames(
          className,
          `cta-button cta-button--${theme.replace(/\s/g, '').toLowerCase()}`,
          fullWidth && 'cta-button--fullWidth'
        )}
        button={BUTTON_THEME_STYLE[theme.replace(/\s/g, '').toUpperCase()]}
        target={targetBlank || '_blank'}
        rel="noopener noreferrer"
        href={onClick ? undefined : href}
        {...rest}
      >
        {text}
        {(icon || isHomepageButton) && (
          <Icon
            variant={isHomepageButton ? ICON_VARIANT.ARROW_CHEVRON_RIGHT : icon}
            size={LAB_ICON_SIZE.SM}
            color={isHomepageButton ? COLOR.BLUE : COLOR.TRANSPARENT}
          />
        )}
      </Link>
    </div>
  );
};

export default Button;
