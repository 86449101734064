import React, { useState } from 'react';
import 'styled-components/macro';
import classNames from 'classnames';

import { BasisProvider, defaultTheme, Form, Select, Input, Text } from 'basis';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { FramedContainer } from '@latitude/framed-container';

const RADIX_DECIMAL = 10;
const currency = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD'
});

export const InstalmentInterestFreeCalculatorAssignedMinimums = ({
  annualFee,
  establishmentFee,
  establishmentFeeText,
  minimumAmount,
  monthlyFee,
  monthlyTerms,
  importantInfo,
  productName,
  isGemVisa,
  heading,
  monthlyFeeText,
  annualFeeText
}) => {
  const [amountLimit, setAmountLimit] = useState({
    min: monthlyTerms[0].minFee,
    max: monthlyTerms[0].maxAmount
  });
  const [validationMsg, setValidationMsg] = useState(null);

  const interestFreeOptions = monthlyTerms.map(term => ({
    label: `${term.duration} Months`,
    value: term.duration.toString()
  }));

  const paymentFrequencyOptions = [
    {
      label: 'Per month',
      value: '0',
      perYear: 12
    },
    {
      label: 'Per week',
      value: '1',
      perYear: 52
    },
    {
      label: 'Per day',
      value: '2',
      perYear: 365
    }
  ];

  const initialValues = {
    interestFreePeriod: interestFreeOptions[0].value,
    purchaseAmount: minimumAmount.toString(),
    paymentFrequency: paymentFrequencyOptions[0].value
  };

  /*
   * New requirement for Gem Finance
   * to have custom establishment fee text.
   * All other site calculators to follow existing standard
   */
  const renderEstablishmentFee = () => {
    // If using "annualFeeText" then don't need to append anything else
    if (annualFeeText) {
      return null;
    }

    if (establishmentFeeText) {
      return establishmentFeeText;
    }
    return `Add $${establishmentFee} once only establishment fee for new customers.`;
  };

  /*
   * Requirement for Gem Finance
   * to have custom annnual fee text.
   * All other site calculators to follow existing standard
   */
  const renderAnnualFeeText = () => {
    // If using "annualFeeText" then don't need to append anything else
    if (annualFeeText) {
      return null;
    }
    if (annualFee > 0) {
      return `Includes a $${annualFee} annual account fee, charged after your first purchase
      and each anniversary date. `;
    }

    return null;
  };

  const renderMonthlyFeeText = () => {
    if (monthlyFeeText) {
      return monthlyFeeText;
    }

    if (annualFee > 0) {
      return `Excludes $${annualFee} annual account fee. `;
    }
    return null;
  };

  function formatAmountHelpText() {
    if (amountLimit.max) {
      return `Min purchase ${currency.format(
        amountLimit.min
      )} Max purchase ${currency.format(amountLimit.max)}`;
    } else {
      return `Min purchase ${currency.format(amountLimit.min)}`;
    }
  }

  const handleDurationChange = e => {
    monthlyTerms.forEach((monthlyTerm, index) => {
      if (monthlyTerm.duration == e.selectedOption.value) {
        setAmountLimit({ min: monthlyTerm.minFee, max: monthlyTerm.maxAmount });
      }
    });
  };

  return (
    <BasisProvider theme={defaultTheme}>
      <Form initialValues={initialValues}>
        {({ state }) => {
          // console.log(JSON.stringify(state));

          const interestFreePeriod = parseInt(
            state.values.interestFreePeriod,
            RADIX_DECIMAL
          );
          const purchaseAmount = parseFloat(state.values.purchaseAmount);
          const paymentFrequency =
            paymentFrequencyOptions[
              parseInt(state.values.paymentFrequency, RADIX_DECIMAL)
            ];

          let result;
          let total;

          if (purchaseAmount < amountLimit.min) {
            //   $('.error').text('Interest free is only available on amounts of $' + minimum_amount + ' or more').slideDown();
            //   return "$0.00";
            result = 0;
            total = 0;
            setValidationMsg(
              `Purchase amount must be greater than ${currency.format(
                amountLimit.min
              )}`
            );
          } else if (purchaseAmount > amountLimit.max) {
            //   $('.error').text('Interest free is only available on amounts of $' + minimum_amount + ' or more').slideDown();
            //   return "$0.00";
            result = 0;
            total = 0;
            setValidationMsg(
              `Purchase amount must be less than ${currency.format(
                amountLimit.max
              )}`
            );
          } else {
            total =
              interestFreePeriod * monthlyFee +
              establishmentFee +
              purchaseAmount +
              annualFee * Math.round(interestFreePeriod / 12);
            setValidationMsg(null);
          }

          /**
           * Note — Gem Visa AU currently has an edge case requirement where monthly fee should not
           * include the monthly fee in the per monthly output, but should include it within the total amount paid output.
           * The below conditional will accommodate this scenario until it is no longer required.
           */

          if (isGemVisa) {
            result =
              (purchaseAmount / interestFreePeriod) *
              (12 / paymentFrequency.perYear);
          } else {
            result =
              (purchaseAmount / interestFreePeriod) *
                (12 / paymentFrequency.perYear) +
              monthlyFee * (12 / paymentFrequency.perYear);
          }

          return (
            <div
              css={`
                > div {
                  &:nth-child(2),
                  &:last-child {
                    margin-top: 20px;
                    text-align: center;
                  }
                }

                @media (min-width: ${BREAKPOINT.XS}) {
                  display: flex;
                  align-items: center;

                  > div:first-child {
                    width: 42%;
                  }

                  > div:last-child {
                    width: 52%;

                    @media (min-width: ${BREAKPOINT.LG}) {
                      width: 45.5%;
                    }
                  }
                }
              `}
            >
              <div
                css={`
                  > div:not(:first-child) {
                    margin-top: 20px;
                  }
                `}
              >
                <div
                  className={classNames(
                    'purchaseAmount',
                    validationMsg ? 'isNotValid' : ''
                  )}
                >
                  <Input
                    name="purchaseAmount"
                    label="Purchase amount"
                    type="text"
                    helpText={formatAmountHelpText()}
                  />
                  {validationMsg && (
                    <div
                      className="errMessage"
                      css={{
                        color: 'red',
                        fontStyle: 'italic',
                        borderTop: '3px solid red',
                        width: '100%'
                      }}
                    >
                      {validationMsg}
                    </div>
                  )}
                </div>
                <div
                  css={`
                    @media (min-width: ${BREAKPOINT.LG}) {
                      display: flex;
                      justify-content: space-between;
                      > div {
                        width: 48%;
                      }
                    }
                  `}
                >
                  <Select
                    name="interestFreePeriod"
                    label="Interest free period"
                    options={interestFreeOptions}
                    onChange={handleDurationChange}
                  />
                  <Select
                    name="paymentFrequency"
                    label="Payment frequency"
                    options={paymentFrequencyOptions}
                  />
                </div>
              </div>
              <FramedContainer
                theme={{
                  contentBorderColor: COLOR.BLUE_SKY,
                  frameColor: '#B1DBFF'
                }}
              >
                <div
                  css={`
                    background: ${COLOR.BLUE_SKY};
                    padding: 32px;

                    @media (min-width: ${BREAKPOINT.XS}) {
                      padding: 8px;
                    }

                    @media (min-width: ${BREAKPOINT.LG}) {
                      padding: 16px 32px;
                    }
                  `}
                >
                  <div>
                    <Text textStyle="body1">
                      <strong
                        css={`
                          @media (min-width: ${BREAKPOINT.LG}) {
                            font-size: 1.2em;
                          }
                        `}
                      >
                        {heading || 'Your Interest Free payment plan'}
                      </strong>
                    </Text>
                    <div
                      css={`
                        margin: 8px 0;
                      `}
                    >
                      <Text textStyle="body1" color="primary.blue.t100">
                        from
                      </Text>
                      <Text textStyle="heading3" color="primary.blue.t100">
                        <span
                          css={`
                            @media (min-width: ${BREAKPOINT.LG}) {
                              font-size: 1.5em;
                            }
                            &:before {
                              content: '';
                              position: absolute;
                              top: 57px;
                              width: 34px;
                              height: 81px;
                              margin-left: -14px;
                              background-size: contain;

                              @media (min-width: ${BREAKPOINT.XS}) {
                                top: 35px;
                                margin-left: -14px;
                              }

                              @media (min-width: ${BREAKPOINT.MD}) {
                                top: 64px;
                                height: 60px;
                                margin-left: -38px;
                              }
                            }
                            &:after {
                              content: '';
                              position: absolute;
                              top: 72px;
                              width: 26px;
                              height: 60px;
                              margin-left: -14px;
                              background-size: contain;

                              @media (min-width: ${BREAKPOINT.XS}) {
                                top: 49px;
                                margin-left: -12px;
                              }

                              @media (min-width: ${BREAKPOINT.MD}) {
                                top: 65px;
                                width: 35px;
                                height: 52px;
                                margin-left: 4px;
                              }
                            }
                          `}
                        >
                          {validationMsg ? (
                            <>Not Available</>
                          ) : (
                            <>{currency.format(result)}</>
                          )}
                        </span>
                      </Text>
                      <Text textStyle="body1" color="primary.blue.t100">
                        {paymentFrequency.label.toLowerCase()}
                        <sup>*</sup>
                      </Text>
                    </div>

                    <Text textStyle="body2">
                      <sup>*</sup>
                      {renderMonthlyFeeText()}
                      {isGemVisa
                        ? `Instalment excludes $${monthlyFee} monthly account service fee.`
                        : monthlyFee > 0 &&
                          `Instalment includes a $${monthlyFee} monthly account servicing fee.`}

                      {paymentFrequency.label !== 'Per month' &&
                        'Monthly repayments required, exact amount specified in your statement.'}
                    </Text>
                  </div>
                  <hr
                    css={`
                      border-top: 2px solid ${COLOR.BLUE_LIGHT};
                    `}
                  />
                  <div>
                    <Text textStyle="subtitle2" color="primary.blue.t100">
                      <strong
                        css={`
                          font-weight: 400 !important;
                        `}
                      >
                        Total amount paid<sup>^</sup>
                      </strong>
                    </Text>
                    <Text textStyle="heading4" color="primary.blue.t100">
                      <span
                        css={`
                          @media (min-width: ${BREAKPOINT.LG}) {
                            font-size: 1.1em;
                          }
                        `}
                      >
                        {currency.format(total)}
                      </span>
                    </Text>
                    <Text textStyle="body2">
                      <span
                        css={`
                          margin-top: 8px;
                          display: block;
                        `}
                      >
                        <sup>^</sup>
                        {annualFeeText && `${annualFeeText}`}
                        {productName && `${productName}: `}
                        {renderAnnualFeeText()}
                        {isGemVisa
                          ? `Includes $${monthlyFee} monthly account service fee.`
                          : monthlyFee > 0 &&
                            `Includes a $${monthlyFee} monthly account servicing fee.`}
                        {renderEstablishmentFee()}
                      </span>
                    </Text>
                  </div>
                </div>
              </FramedContainer>
            </div>
          );
        }}
      </Form>
      <div
        css={`
          margin-top: 20px;
          @media (min-width: ${BREAKPOINT.LG}) {
            margin-top: 40px;
          }
          > p {
            margin-bottom: 20px;
          }
        `}
      >
        <Text textStyle="heading4" align="center">
          Important things to remember
        </Text>

        {importantInfo ? (
          importantInfo.map(line => <Text>{line}</Text>)
        ) : (
          <>
            <Text>
              These repayments are for Equal monthly payment plans only and do
              not take into account any other purchases or balances.
            </Text>

            {establishmentFee > 0 && (
              <Text>
                The instalment and total estimated price amounts exclude the
                one-off ${establishmentFee} establishment fee payable by new
                customers.
              </Text>
            )}

            <Text>
              The monthly, weekly and daily repayments are approximate only.
            </Text>

            <Text>
              Monthly repayments are required, exact amount specified in the
              customer's statement.
            </Text>

            <Text>
              Minimum financed amount applies. See current promotions for terms
              and conditions.
            </Text>

            <Text>
              Various plans only available during specific promotional periods.
            </Text>
          </>
        )}
      </div>
    </BasisProvider>
  );
};
