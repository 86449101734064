import React from 'react';

import Section from '@latitude/section';
import './pageHeader.css';

function PageHeaderSection({
  title,
  subTitle,
  pageImage,
  pageImageAlt = '',
  children
}) {
  return (
    <Section className="lfs-pageheader">
      <div className="lfs-pageheader-item">
        <div className="lfs-pageheader-image">
          <img src={pageImage} alt={pageImageAlt} />
        </div>

        <div className="lfs-pageheader-text">
          <h1 className="lfs-pageheader-title">{title}</h1>
          {subTitle && (
            <div className="lfs-pageheader-subtitle">{subTitle}</div>
          )}
          {children && <div className="lfs-pageheader-content">{children}</div>}
        </div>
      </div>
    </Section>
  );
}

export default PageHeaderSection;
